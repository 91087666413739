import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form",staticClass:"maxWidth"},[_c(VRow,{style:({maxWidth:'250px'})},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('ask.form.fields[0]')},model:{value:(_vm.fields[0].value),callback:function ($$v) {_vm.$set(_vm.fields[0], "value", $$v)},expression:"fields[0].value"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('ask.form.fields[1]')},model:{value:(_vm.fields[1].value),callback:function ($$v) {_vm.$set(_vm.fields[1], "value", $$v)},expression:"fields[1].value"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":"Email"},model:{value:(_vm.fields[2].value),callback:function ($$v) {_vm.$set(_vm.fields[2], "value", $$v)},expression:"fields[2].value"}})],1)],1),_c(VRow,{staticStyle:{"{maxWidth":"inherit}"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"counter":"50","label":_vm.$t('ask.form.fields[2]')},model:{value:(_vm.fields[3].value),callback:function ($$v) {_vm.$set(_vm.fields[3], "value", $$v)},expression:"fields[3].value"}}),_c(VTextarea,{attrs:{"rules":[_vm.rules.required],"counter":"2000","label":_vm.$t('ask.form.fields[3]'),"no-resize":""},model:{value:(_vm.fields[4].value),callback:function ($$v) {_vm.$set(_vm.fields[4], "value", $$v)},expression:"fields[4].value"}})],1)],1),_c(VBtn,{attrs:{"color":"primary","outlined":"","rounded":""},on:{"click":function($event){return _vm.formSubmit()}}},[_vm._v(_vm._s(_vm.$t('ask.form.btn')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }