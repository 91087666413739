<template>
  <v-row justify-center>
    <v-col>
      <v-dialog :value="OpenRequestDialog" @click:outside="CloseDialog()" max-width="320">
        <!-- loading card -->
        <v-card class="card" color="background_nav" v-show="open == 'loading'">
          <v-progress-circular indeterminate :size="150" color="accent"></v-progress-circular>
        </v-card>

        <!-- error card -->
      <span v-show="open == 'error'">
          <div id="topBar" class="error">
            <v-icon color="white" size="70">mdi-alert-circle</v-icon>
          </div>
          <v-card class="Dialog" color="background_nav">
            <v-card-title class="headline">Πρόβλημα </v-card-title>
            <v-card-text><span v-html="ErrorMessage"></span></v-card-text>
            <v-card-actions mb-5>
                <v-spacer></v-spacer>
              <v-btn color="primary" text @click="CloseDialog()">ΟΚ</v-btn>
            </v-card-actions>
          </v-card>
        </span>

        <!-- success card -->
        <span v-show="open == 'success'" >
          <div id="topBar" class="green lighten-1">
            <v-icon color="white" size="70">mdi-check-circle-outline</v-icon>
          </div>
          <v-card class="Dialog" color="background_nav">
            <v-card-title class="headline">Ολοκληρώθηκε </v-card-title>
            <v-card-text><span v-html="SuccessMessage"></span></v-card-text>
            <v-card-actions mb-5>
                <v-spacer></v-spacer>
              <v-btn color="primary" text @click="CloseDialog()">ΟΚ</v-btn>
            </v-card-actions>
          </v-card>
        </span>

      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { bus } from '../main';
export default {
  name: 'MessageCard',
  data: () => {
    return {
      open: "",
      ErrorMessage: "Υπάρχει κάποιο πρόβλημα με την φόρμα. Παρακαλώ επικοινωνίστε μαζί μας στο support@kontomourtos.gr.",
      SuccessMessage: "Το αίτημα σας καταχωρήθηκε με επιτυχία.",
      OpenRequestDialog: false
    }
  },
  methods: {
    CloseDialog() {
      this.OpenRequestDialog = false
      bus.$emit('MessageCard','done');
    }
  },
  created(){
    bus.$on('AskFormSubmit',(data) => {
      this.open = data
      this.OpenRequestDialog = true
    })
  }
}
</script>

<style lang="scss" scoped>
.card{
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#topBar{
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

