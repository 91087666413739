<template>
    <v-form class="maxWidth" ref="form">
      <v-row :style="{maxWidth:'250px'}"> 
        <v-col cols="12">
          <v-text-field v-model="fields[0].value" :rules="[rules.required]" :label="$t('ask.form.fields[0]')"></v-text-field>
          <v-text-field v-model="fields[1].value" :rules="[rules.required]" :label="$t('ask.form.fields[1]')" ></v-text-field>
          <v-text-field v-model="fields[2].value" :rules="[rules.required, rules.email]" label="Email" ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="{maxWidth:inherit}">
        <v-col cols="12">
          <v-text-field v-model="fields[3].value" :rules="[rules.required]" counter=50 :label="$t('ask.form.fields[2]')"></v-text-field>
          <v-textarea v-model="fields[4].value" :rules="[rules.required]" counter=2000 :label="$t('ask.form.fields[3]')" no-resize></v-textarea>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="formSubmit()" outlined rounded>{{ $t('ask.form.btn') }}</v-btn>
    </v-form>
</template>

<script>
import { bus } from '../main';
export default {
  name: 'AskForm',
  data: () => ({
    fields: [
      {name: "name", value: ""},
      {name: "surname", value: ""},
      {name: "email", value: ""},
      {name: "title", value: ""},
      {name: "description", value: ""}
    ]
  }),
  computed: {
    rules () {
          return {
            required: value => !!value || "Το πεδίο είναι υποχρεωτικό",
            email: value => {
              if(value) return (value.match(/^\w+([".-]?\w+)*@\w+([".-]?\w+)*\.\w{2,3}/gm)) ? true : 'Το email είναι λάθος'
              else return false  
            }
          }
    },
    formSubmitData(){
      let data = {};
      for(let field of this.fields){
        data[field.name] = field.value
      }
      return data;
    }
  },
  methods: {
    formSubmit(){
      let url = "https://prod-78.westeurope.logic.azure.com:443/workflows/f61261a98a1b4807bb0983237b1b4007/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=79GKfdNurW8jxbhRZOKrPqLSSqGZdHzIleYIrhyy990";
      if(this.$refs.form.validate()){
        bus.$emit('AskFormSubmit','loading');
        fetch(url, { 
          method: 'POST', 
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(this.formSubmitData) 
        })
        .then(function (response){
          if(response.status == 202){
            //message ok
            bus.$emit('AskFormSubmit','success');
          }else{
            //message error
            bus.$emit('AskFormSubmit','error');
          }
        })
        .catch(() => {
          //message error
          bus.$emit('AskFormSubmit','error');
        });
      }
    }
  },
  created(){
    bus.$on('MessageCard',(data) => {
      if(data == 'done') {
        this.$refs.form.reset()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.v-text-field{
  padding: 0;
  margin: 0;
}
</style>