<template>
  <v-container class="fullpage d-flex flex-column">
    <v-row justify="center" align="end">
      <v-col cols="12" lg="6">
        <p class="text-h2 text-lg-h1">{{ $t('ask.title') }}</p><br>
      </v-col>
    </v-row>
    <!-- ---------------- start contact ifo ---------------- -->
    <v-row justify="center">
      <v-col cols="12" sm="6" lg="3">
        <v-row>
          <v-col>
            <p class="text-Subtitle-2 accent--text">{{ $t('ask.contacts[0].title') }}</p>
            <v-list two-line color="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="$t('ask.contacts[0].items[0].subtitle')"></v-list-item-subtitle>
                  <v-list-item-title v-text="$t('ask.contacts[0].items[0].info')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="$t('ask.contacts[0].items[1].subtitle')"></v-list-item-subtitle>
                  <v-list-item-title v-text="$t('ask.contacts[0].items[1].info')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="$t('ask.contacts[0].items[2].subtitle')"></v-list-item-subtitle>
                  <v-list-item-action>
                    <v-btn color="primary" href="https://www.facebook.com/kontomourtos" target="_blank" rounded outlined>
                      <v-icon left >mdi-facebook</v-icon> Facebook
                    </v-btn>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="text-Subtitle-2 accent--text">{{ $t('ask.contacts[1].title') }}</p>
            <v-list two-line color="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="$t('ask.contacts[1].items[0].subtitle')"></v-list-item-subtitle>
                  <v-list-item-title v-text="$t('ask.contacts[1].items[0].info')"></v-list-item-title>
                  <v-list-item-action>
                    <v-btn color="primary" @click="mapsSelector('Athens')" rounded outlined>
                      <v-icon left >mdi-map-marker</v-icon>{{ $t('ask.contacts[1].items[0].btn') }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle v-text="$t('ask.contacts[1].items[1].subtitle')"></v-list-item-subtitle>
                  <v-list-item-title v-text="$t('ask.contacts[1].items[1].info')"></v-list-item-title>
                  <v-list-item-action>
                    <v-btn color="primary" @click="mapsSelector('Paros')" rounded outlined>
                      <v-icon left >mdi-map-marker</v-icon>{{ $t('ask.contacts[1].items[1].btn') }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <!-- ------------ // end contact ifo // ---------------- -->
      <!-- ------------ start form ---------------- -->
      <v-col cols="12" sm="6" lg="3" class="mt-8 mt-sm-0">
        <p  class="text-Subtitle-2 accent--text">{{ $t('ask.form.title') }}</p>
        <AskForm/>
        <MessageCard/>
      </v-col>
      <!-- ------------ // end form // ---------------- -->
    </v-row>
  </v-container>
</template>

<script>
import AskForm from '../components/AskForm';
import MessageCard from '../components/MessageCard';
export default {
  name: 'Ask',
  components: {
    AskForm,
    MessageCard
  },
  methods: {
    mapsSelector(place){
      let url;
      if(place == "Athens"){
        url = "//goo.gl/maps/QSBesaVPQDR2";
      }else{
        url = "//goo.gl/maps/BHnb8bT7id62";
      }

      if /* if we're on iOS, open in Apple Maps */
        ((navigator.platform.indexOf("iPhone") != -1) || 
        (navigator.platform.indexOf("iPod") != -1) || 
        (navigator.platform.indexOf("iPad") != -1)){
        window.open("maps:"+url);
      }else{ /* else use Google */
        //window.open("https://maps.google.com/maps?daddr=lat,long&amp;ll=");
        window.open("https:"+url);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list, .v-list-item{
  padding: 0;
}
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child){
  margin-left: 0;
}
</style>